import React from 'react'
import { NavLink, useMatch } from 'react-router-dom'
import './Navbar.css'

import Resume from '../../assets/Resume.pdf'

const Navbar = () => {
    const activeStyles = {
        padding: "4px 7px",
        borderRadius: "3px",
        border: "1.5px solid #212121",
        backgroundColor: "#f4f4f4"
    }

    const homeMatch = useMatch("/")
    const portfolioMatch = useMatch("/portfolio/*", { end: false })

    return (
      <nav className="navbar-container"> 
        <div className="navbar">
          <ul className="nav-menu">
            <li className="nav-item">
              <NavLink 
                  to='/' 
                  className={homeMatch ? "nav-link active" : "nav-link"} 
                  style={homeMatch ? activeStyles : {}}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink 
                  to='/portfolio'
                  className={portfolioMatch ? "nav-link active" : "nav-link"} 
                  style={portfolioMatch ? activeStyles : {}}
              >
                Portfolio
              </NavLink>
            </li>
            <li className="nav-item">
              <a 
                  href={Resume}
                  download="Liam Campbell Resume.pdf" 
                  className="download-button"
              >
              Download Resume
              </a>
            </li>
          </ul>
        </div>
      </nav>
    )
}

export default Navbar