import React from 'react'
import Three from 'components/Three/Three'
import LetsConnect from '../Home/Sections/LetsConnect/LetsConnect'
import PortfolioBreadcrumbs from '../../components/PortfolioBreadcrumbs/PortfolioBreadcrumbs'
import HeaderImages from '../../components/HeaderImages/HeaderImages'
import PortfolioTitle from '../../components/PortfolioTitle/PortfolioTitle'
import OpportunitySection from '../../components/OpportunitySection/OpportunitySection'
import TimelineSection from '../../components/TimelineSection/TimelineSection'
import WireframeSection from 'components/WireframeSection/WireframeSection'
import PortfolioMedia from '../../components/PortfolioMedia/PortfolioMedia'

import CabynIcon from '../../assets/cabyn-icon.svg'

import HeaderImage1 from '../../assets/cabyn-media/cabyn-header-1.png'
import HeaderImage2 from '../../assets/cabyn-media/cabyn-listings.png'
import HeaderImage3 from '../../assets/cabyn-media/cabyn-dash-metrics.png'

import CabynWireframe from 'assets/cabyn-media/cabyn-wireframe-1.jpg'
import CabynStyleGuide from 'assets/cabyn-media/cabyn-styleguide.jpg'
import CabynStyleGuideMobile from 'assets/cabyn-media/cabyn-styleguide-mobile.jpg'

import BlogPost from '../../assets/cabyn-media/cabyn-blog-post.png'
import BrowseMobileAppSettings from '../../assets/cabyn-media/cabyn-browse-mobile-app-settings.png'
import BrowseMobileApp from '../../assets/cabyn-media/cabyn-browse-mobile-app.png'
import BrowseMoreHomes from '../../assets/cabyn-media/cabyn-browse-more-homes.png'
import EmailSignupConfirmation from '../../assets/cabyn-media/cabyn-email-signup-confirmation.png'
import GroupPurchaseNotification from '../../assets/cabyn-media/cabyn-group-purchase-notification.png'
import HandMadeIcons from '../../assets/cabyn-media/cabyn-hand-made-icons.png'
import HowItWorks from '../../assets/cabyn-media/cabyn-how-it-works.png'
import MortgageCalculator from '../../assets/cabyn-media/cabyn-mortgage-calculator.png'
import RentComparisonIllustration from '../../assets/cabyn-media/cabyn-rent-comparison-illustration.png'
import ResourcesSection from '../../assets/cabyn-media/cabyn-resources-section.png'

import { CabynPreview1, CabynPreview2, CabynPreview3 } from 'assets'

const Cabyn = () => {
    const data = {
        companyName: "Cabyn",
        icon: CabynIcon,
        headerImages: {
            firstImage: CabynPreview1,
            secondImage: CabynPreview2,
            thirdImage: CabynPreview3
        },
        location: "Austin, TX",
        duration: "6 months",
        teamSize: "4",
        roles: [
            "Product",
            "Brand",
            "Frontend development",
            "Co-founder"
        ],
        areas: [
            "Prop tech",
            "Real estate",
            "Investments",
            "Web application"
        ],
        summary: "Cabyn was a prop tech marketplace where young people could co-buy homes together using fractional ownership. We found that splitting the cost of a home purchase up to four ways was price equivalent to renting in a major city. Our venture ended quickly but it was a great experience.",
        opportunity: (
            <p>
                Small marketing firms typically spend 25-30% of their marketing budgets 
                on content marketing, and sourcing content tends to be <strong>complex 
                and expensive.</strong> The founders at Fynch saw an opportunity for a 
                centralized app for <strong>ordering bundles of diverse, rich, and 
                seo-optimized content quickly and affordably.</strong>
            </p>
        ),
        timeline: [
            {title: "Research", content: "Competitive analysis, surveys, personas", width: "362px"},
            {title: "Ideation", content: "Sketches, moodboards, affinity mapping", width: "206px"},
            {title: "Design", content: "Wireframes, design systems, prototyping, usability testing", width: "258px"},
            {title: "Branding", content: "Visual identity, voice & tone", width: "362px"}
        ],
        media: [
            {src: HeaderImage3, alt: "Dashboard metrics"},
            {src: HeaderImage2, alt: "Listings"},
            {src: BlogPost, alt: "Blog post"},
            {src: BrowseMoreHomes, alt: "Browse more homes"},
            {src: EmailSignupConfirmation, alt: "Email signup confirmation"},
            {src: GroupPurchaseNotification, alt: "Group purchase notification"},
            {src: HandMadeIcons, alt: "Some handmade icons"},
            {src: HowItWorks, alt: "How it works"},
            {src: MortgageCalculator, alt: "Mortgage calculator"},
            {src: RentComparisonIllustration, alt: "Rent comparison illustration"},
            {src: ResourcesSection, alt: "Resources section"},
            {src: BrowseMobileApp, alt: "Mobile app browsing"},
            {src: BrowseMobileAppSettings, alt: "Mobile app browsing settings"}
        ],
        wireframes: [
            {src: CabynWireframe, alt: "Wireframe"},
            {src: CabynWireframe, alt: "Wireframe"},
        ],
        styleguides: [
            {src: CabynStyleGuide, alt: "Style Guide"},
            {src: CabynStyleGuideMobile, alt: "Style Guide"},
        ]
    }

    return (
        <>
            <div className="portfolio-renderer-container">
                <Three 
                    viewHeight={270}
                    ind={47}
                />
            </div>
            <PortfolioBreadcrumbs data={data} />
            <HeaderImages data={data} />
            <PortfolioTitle data={data} />
            <WireframeSection data={data.wireframes} />
            <WireframeSection data={data.styleguides} />
            <PortfolioMedia data={data} />
            <LetsConnect />
        </>
    )
}

export default Cabyn