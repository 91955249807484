import React, { useState } from 'react'
import './AddMeOnLinkedinButton.css'
import LinkedinIcon from '../../../assets/linkedin-icon.svg'

const AddMeOnLinkedinButton = () => {
    const [hover, setHover] = useState(false);
    const [subtextVisible, setSubtextVisible] = useState(false);

    const handleButtonClick = () => {
        window.open("https://linkedin.com/in/liamacampbell/", "_blank", "noreferrer");
        setTimeout(() => {
            setSubtextVisible(false);
        }, 1000);  // Introduce delay for resetting message
    };

    const handleMouseEnter = () => {
        setHover(true);
        setSubtextVisible(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
        setSubtextVisible(false);
    }

    return (
        <div className='add-me-on-linkedin-btn-container'>
            <button 
                className='add-me-on-linkedin-btn' 
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleButtonClick}
            >
                <img className='linkedin-icon' src={LinkedinIcon} alt='linkedin icon' />
                Add me on Linkedin
            </button>
            <p className={`add-me-on-linkedin-subtext ${hover && subtextVisible ? 'visible' : ''}`}>Go to my profile</p>
        </div>
    )
}

export default AddMeOnLinkedinButton;