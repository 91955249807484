// Third party libraries
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// Shared libraries
import Divider from '@mui/material/Divider'

// Relative imports
import PortfolioCard from 'components/PortfolioCard/PortfolioCard'
import { projects } from 'projects'

// Stylesheets
import './PortfolioCards.css'

const PortfolioCards = () => {
  const navigate = useNavigate();

  // Preload images
  useEffect(() => {
    const imagesToPreload = [];
    for (let i = 0; i < projects.length; i++) {
      imagesToPreload.push(projects[i].thumbnail)
    }

    imagesToPreload.forEach(image => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  return (
    <div className='portfolio-cards-container'>
      <div className="portfolio-cards-title">
        <h1>Liam's work</h1>
        <p>
          Select product & design work. Tap on each card to learn more.
        </p>
        <Divider />
      </div>
      <div className="portfolio-card-grid">
        {projects.map(project => (
          <PortfolioCard key={project.name}
            title={project.name} 
            thumbnail={project.thumbnail} 
            icon={project.icon} 
            onClick={() => navigate(project.path)} 
          />
        ))}
      </div>
    </div>
  )
}

export default PortfolioCards