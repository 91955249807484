import React from 'react'
import { useNavigate } from 'react-router-dom'
import './SeeProjectButton.css'
import darkArrow from 'assets/see-project-arrow-dark.svg'
import lightArrow from 'assets/see-project-arrow-light.svg'

const SeeProjectButton = ( props ) => {
  const { text, mode, portfolioPath } = props;
  const navigate = useNavigate();

  return (
    <div className={`see-project-btn-${mode}`} onClick={() => navigate(`/portfolio/${portfolioPath}`)}>
        <button className={`see-project-btn-text-${mode}`}>{text}</button>
        <img src={mode === 'dark' ? darkArrow : lightArrow} alt="See Project Arrow" />
    </div>
  )
}

export default SeeProjectButton