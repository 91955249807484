import React from 'react'
import './Skills.css'
import SkillsWidget from '../../../../components/SkillsWidget/SkillsWidget'

const Skills = () => {

    return (
        <div className="skills-container" id="skills-section">
            <div className="skills">
                <h1>Skills</h1>
                <SkillsWidget />
            </div>
        </div>
    )
}

export default Skills