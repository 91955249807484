import React, { useState, useEffect } from 'react'
import './WireframeSection.css'

const WireframeSection = (props) => {
  const wireframes = props.data;
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 940) {
        setImageIndex(1);
      } else {
        setImageIndex(0);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='portfolio-wireframe-container'>
        <div className="portfolio-wireframe">
            <h1>{wireframes[imageIndex].alt}</h1>
            <img className="wireframe-image" src={wireframes[imageIndex].src} alt={wireframes[imageIndex].alt} />
        </div>
    </div>
  )
}

export default WireframeSection