const skills = [
  {
    type: "Design",
    skills: 
      [
        {
            name: "Figma",
            type: "hard"
        },
        {
            name: "Adobe Illustrator",
            type: "hard"
        },
        {
            name: "User experience design",
            type: "soft"
        },
        {
            name: "High-fidelity prototyping",
            type: "soft"
        },
        {
            name: "Animation",
            type: "soft"
        },
        {
            name: "Design systems",
            type: "soft"
        },
        {
            name: "Mobile UX",
            type: "soft"
        },
        {
            name: "Information architecture",
            type: "soft"
        },
        {
            name: "User research",
            type: "soft"
        },
        {
          name: "Blender 3D",
          type: "hard"
        }
      ]
  },
  {
    type: "Development",
    skills:
    [
      {
        name: "HTML",
        type: "hard"
      },
      {
          name: "CSS",
          type: "hard"
      },
      {
          name: "JavaScript",
          type: "hard"
      },
      {
          name: "React",
          type: "hard"
      },
      {
          name: "Wordpress",
          type: "hard"
      },
      {
        name: "Three.js",
        type: "hard"
      }
    ]
  },
  {
    type: "Leadership",
    skills:
    [
      {
        name: "Agile project management",
        type: "soft"
      },
      {
          name: "Feature prioritization",
          type: "soft"
      },
      {
          name: "Scrum",
          type: "soft"
      },
      {
          name: "Market research",
          type: "soft"
      },
      {
          name: "Communication",
          type: "soft"
      }
    ]
  },
  {
    type: "Other",
    skills:
    [
      {
        name: "AI prompting",
        type: "hard"
      },
      {
          name: "Statistics",
          type: "soft"
      },
      {
          name: "Data analysis",
          type: "soft"
      }
    ]
  },
]

export { skills };