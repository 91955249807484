import React from 'react'
import Three from 'components/Three/Three'
import LetsConnect from '../Home/Sections/LetsConnect/LetsConnect'
import PortfolioBreadcrumbs from '../../components/PortfolioBreadcrumbs/PortfolioBreadcrumbs'
import HeaderImages from '../../components/HeaderImages/HeaderImages'
import PortfolioTitle from '../../components/PortfolioTitle/PortfolioTitle'
import WireframeSection from 'components/WireframeSection/WireframeSection'
import PortfolioMedia from '../../components/PortfolioMedia/PortfolioMedia'

import RepXIcon from '../../assets/repx-icon.svg'

import Header1 from '../../assets/repx-media/repx-header-1.png'
import Header2 from '../../assets/repx-media/repx-header-2.png'
import Header3 from '../../assets/repx-media/repx-header-3.png'

import RepxWireframe from 'assets/repx-media/repx-wireframe-1.jpg'
import RepxStyleGuide from 'assets/repx-media/repx-styleguide.jpg'
import RepxStyleGuideMobile from 'assets/repx-media/repx-styleguide-mobile.jpg'

import Homepage from '../../assets/repx-media/repx-homepage.png'
import CTAForm from '../../assets/repx-media/repx-cta-form.mp4'
import FunnelCards1 from '../../assets/repx-media/repx-funnel-cards-1.mp4'
import FunnelCards2 from '../../assets/repx-media/repx-funnel-cards-2.mp4'
import CaseWidget from '../../assets/repx-media/repx-case-widget.png'
import CTAButton from '../../assets/repx-media/repx-cta-button.png'
import MoreDetailsSection from '../../assets/repx-media/repx-more-details-section.png'
import Testimonials1 from '../../assets/repx-media/repx-testimonials-1.png'
import Testimonials2 from '../../assets/repx-media/repx-testimonials-2.png'

import { RepxPreview1, RepxPreview2, RepxPreview3 } from 'assets'

const ReputationX = () => {
    const data = {
        companyName: "Reputation X",
        icon: RepXIcon,
        headerImages: {
            firstImage: RepxPreview1,
            secondImage: RepxPreview2,
            thirdImage: RepxPreview3
        },
        location: "San Francisco",
        duration: "12 months",
        teamSize: "120",
        roles: [
            "Product",
            "UX",
            "Brand design",
            "Frontend development"
        ],
        areas: [
            "Web application",
            "Marketing",
            "PR"
        ],
        summary: "At Reputation X, I collaborated with the leadership team to reimagine the company’s brand image with new design systems, a new website, and new tools like Reputool.",
        opportunity: (
            <p>
                Small marketing firms typically spend 25-30% of their marketing budgets 
                on content marketing, and sourcing content tends to be <strong>complex 
                and expensive.</strong> The founders at Fynch saw an opportunity for a 
                centralized app for <strong>ordering bundles of diverse, rich, and 
                seo-optimized content quickly and affordably.</strong>
            </p>
        ),
        timeline: [
            {title: "Research", content: "Competitive analysis, surveys, personas", width: "190px"},
            {title: "Ideation", content: "Sketches, moodboards, affinity mapping", width: "190px"},
            {title: "Design", content: "Wireframes, design systems, prototyping, usability testing", width: "190px"},
            {title: "Branding", content: "Visual identity, voice & tone", width: "190px"}
        ],
        media: [
            {src: Homepage, alt: "Homepage hero"},
            {src: CTAForm, alt: "CTA form"},
            {src: FunnelCards1, alt: "Funnel cards 1"},
            {src: FunnelCards2, alt: "Funnel cards 2"},
            {src: CaseWidget, alt: "Case widget"},
            {src: CTAButton, alt: "CTA button"},
            {src: MoreDetailsSection, alt: "More details section"},
            {src: Testimonials1, alt: "Testimonials 1"},
            {src: Testimonials2, alt: "Testimonials 2"}
        ],
        wireframes: [
            {src: RepxWireframe, alt: "Wireframe"},
            {src: RepxWireframe, alt: "Wireframe"},
        ],
        styleguides: [
            {src: RepxStyleGuide, alt: "Style Guide"},
            {src: RepxStyleGuideMobile, alt: "Style Guide"},
        ]
    }

    return (
        <>
            <div className="portfolio-renderer-container">
                <Three 
                    viewHeight={270}
                    ind={47}
                />
            </div>
            <PortfolioBreadcrumbs data={data} />
            <HeaderImages data={data} />
            <PortfolioTitle data={data} />
            <WireframeSection data={data.wireframes} />
            <WireframeSection data={data.styleguides} />
            <PortfolioMedia data={data} />
            <LetsConnect />
        </>
    )
}

export default ReputationX