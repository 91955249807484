import React from 'react'
import Three from 'components/Three/Three'
import LetsConnect from '../Home/Sections/LetsConnect/LetsConnect'
import PortfolioBreadcrumbs from '../../components/PortfolioBreadcrumbs/PortfolioBreadcrumbs'
import HeaderImages from '../../components/HeaderImages/HeaderImages'
import PortfolioTitle from '../../components/PortfolioTitle/PortfolioTitle'
import OpportunitySection from '../../components/OpportunitySection/OpportunitySection'
import TimelineSection from '../../components/TimelineSection/TimelineSection'
import WireframeSection from 'components/WireframeSection/WireframeSection'
import PortfolioMedia from '../../components/PortfolioMedia/PortfolioMedia'

import ReputoolIcon from '../../assets/reputool-icon.svg'

import HeaderImage1 from '../../assets/reputool-media/reputool-header-1.png'
import HeaderImage2 from '../../assets/reputool-media/reputool-header-2.png'
import HeaderImage3 from '../../assets/reputool-media/reputool-header-3.png'

import ReputoolWireframe from 'assets/reputool-media/reputool-wireframe-1.jpg'
import ReputoolStyleGuide from 'assets/reputool-media/reputool-styleguide.jpg'
import ReputoolStyleGuideMobile from 'assets/reputool-media/reputool-styleguide-mobile.jpg'

import Cover from '../../assets/reputool-media/reputool-cover.png'
import ControlledDomainScoring from '../../assets/reputool-media/reputool-controlled-domain-scoring.png'
import CustomizedReputationStrategy from '../../assets/reputool-media/reputool-customized-reputation-strategy.png'
import SearchResultListingTracker from '../../assets/reputool-media/reputool-search-result-listing-tracker.png'
import SentimentGrader from '../../assets/reputool-media/reputool-sentiment-grader.png'
import SocialMediaRecommendations from '../../assets/reputool-media/reputool-social-media-recommendations.png'
import ToolDescription from '../../assets/reputool-media/reputool-tool-description.png'

import { ReputoolPreview1, ReputoolPreview2, ReputoolPreview3 } from 'assets'

const Reputool = () => {
    const data = {
        companyName: "Reputool",
        icon: ReputoolIcon,
        headerImages: {
            firstImage: ReputoolPreview1,
            secondImage: ReputoolPreview2,
            thirdImage: ReputoolPreview3
        },
        location: "San Francisco",
        duration: "12 months",
        teamSize: "4",
        roles: [
            "Product design",
            "UX",
            "Brand design"
        ],
        areas: [
            "Marketing",
            "Web application"
        ],
        summary: "In 2021 I researched, designed, and led development on Reputool, a platform that automatically generates PR strategy templates. Reputool is owned by Reputation X and is now used as an internal tool at several reputation management and PR firms.",
        opportunity: (
            <p>
                Creating PR strategies is a <strong>complex and time-intensive task</strong>, but many aspects of it can be automated. <strong>Reputool is a powerful tool that handles core strategy template generation</strong>, saving PR strategists valuable time and energy.
            </p>
        ),
        timeline: [
            {title: "Research", content: "Competitive analysis, internal interviews", width: "232px"},
            {title: "Ideation", content: "Sketches, concept mapping", width: "206px"},
            {title: "Design", content: "Wireframes, high-fidelity prototyping, usability testing", width: "258px"},
            {title: "Branding", content: "Logo design, design systems", width: "200px"}
        ],
        media: [
            {src: HeaderImage1, alt: "Search appearances tracking"},
            {src: HeaderImage2, alt: "Control & visibility tracking"},
            {src: HeaderImage3, alt: "Sentiment tracking"},
            {src: ControlledDomainScoring, alt: "Controlled domain scoring"},
            {src: Cover, alt: "Cover"},
            {src: CustomizedReputationStrategy, alt: "Customized reputation strategy"},
            {src: SearchResultListingTracker, alt: "Search result listing tracker"},
            {src: SentimentGrader, alt: "Sentiment grader"},
            {src: SocialMediaRecommendations, alt: "Social media recommendations"},
            {src: ToolDescription, alt: "Tool description"}
        ],
        wireframes: [
            {src: ReputoolWireframe, alt: "Wireframe"},
            {src: ReputoolWireframe, alt: "Wireframe"},
        ],
        styleguides: [
            {src: ReputoolStyleGuide, alt: "Style Guide"},
            {src: ReputoolStyleGuideMobile, alt: "Style Guide"},
        ]
    }

    return (
        <>
            <div className="portfolio-renderer-container">
                <Three 
                    viewHeight={270}
                    ind={47}
                />
            </div>
            <PortfolioBreadcrumbs data={data} />
            <HeaderImages data={data} />
            <PortfolioTitle data={data} />
            <OpportunitySection data={data} />
            <TimelineSection data={data} />
            <WireframeSection data={data.wireframes} />
            <WireframeSection data={data.styleguides} />
            <PortfolioMedia data={data} />
            <LetsConnect />
        </>
    )
}

export default Reputool