import React from 'react'
import PortfolioCards from '../../components/PortfolioCards/PortfolioCards'

const Portfolio = () => {
    return (
        <>
            <PortfolioCards />
        </>
    )
}

export default Portfolio