import React from 'react'
import { useNavigate } from 'react-router-dom'

import './PortfolioCard.css'

const PortfolioCard = (props) => {
    const { thumbnail, title, icon, onClick } = props;

    const navigate = useNavigate();

    const navToPortfolio = (t) => {
        navigate(`/portfolio/${t.toLowerCase()}`)
    }

    return (
        <div className='portfolio-card' onClick={onClick}>
            <img className="portfolio-card main" src={thumbnail} alt='fynch screenshot' height={'100%'}/>
            <div className='title-container'>
                <div className="title hover">
                    <img src={icon} alt={title} height={'15px'} onClick={() => navToPortfolio(title)} />
                    <p className='title-text'>{title}</p>
                </div>
            </div>
        </div>
    )
}

export default PortfolioCard