import React from 'react'
import './AboutMe.css'
import Surfing from '../../../../assets/surfing.png'
import Cuka from '../../../../assets/cuka.png'

const AboutMe = () => {
    return (
        <div className='about-me-container'>
            <div className="about-me">
                <h1>About me</h1>
                <p className='about-me-text'>Currently based in San Francisco. When I’m not designing or developing something you can usually find me surfing at Bolinas, CA, roadtripping down the west coast, or trying new restaurants in the city. My Brittany spaniel, Cuka, has followed me into many adventures.</p>
                <div className="about-me-images">
                    {/* <img src={Surfing} alt='surfing' style={{width: '434px', borderRadius: '5px'}}/>
                    <img src={Cuka} alt='cuka' style={{width: '155px', borderRadius: '5px'}} /> */}
                </div>
            </div>
        </div>
    )
}

export default AboutMe