import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import './ProjectsWidget.css'
import { 
  ProjectsWidgetFynch,
  ProjectsWidgetCabyn,
  ProjectsWidgetReputationX,
  ProjectsWidgetReputool,
  FynchPreview1,
  FynchPreview2,
  FynchPreview3,
  CabynPreview1,
  CabynPreview2,
  CabynPreview3,
  RepxPreview1,
  RepxPreview2,
  RepxPreview3,
  ReputoolPreview1,
  ReputoolPreview2,
  ReputoolPreview3,
  Arrow,
  ArrowHover,
  shadow 
} from 'assets';

import { Divider } from '@mui/material'

import { SeeProjectButton } from 'components/Buttons/buttons'

import ThreeJSDice from 'components/ThreeJSDice/ThreeJSDice';
import DiceCabyn from 'components/ThreeJSDice/DiceCabyn';
import DiceFynch from 'components/ThreeJSDice/DiceFynch';
import DiceReputool from 'components/ThreeJSDice/DiceReputool';
import DiceRepx from 'components/ThreeJSDice/DiceRepx';

const ProjectsWidget = ({ onLoaded }) => {
  const [selected, setSelected] = useState(0);
  const [isLeftArrowHovered, setIsLeftArrowHovered] = useState(false);
  const [isRightArrowHovered, setIsRightArrowHovered] = useState(false);
  const navigate = useNavigate();

  const projects = [
    {
      link: ProjectsWidgetReputationX,
      description: `Reputation X performs online reputation management services
                    for a wide range of businesses, executives, and nonprofit 
                    organizations. I was the lead product manager.`,
      name: "Reputation X",
      path: "reputationx",
      tags: ["Product manager", "Marketing", "Public relations"],
      images: [RepxPreview1, RepxPreview2, RepxPreview3],
      dice: "repx"
    },
    {
      link: ProjectsWidgetFynch,
      description: `Fynch makes it possible for small businesses to order 
                    bundles of rich, SEO-optimized content for their blogs, 
                    websites, and social channels with the push of a button.`,
      name: "Fynch",
      path: "fynch",
      tags: ["Product design", "Marketing", "Web application"],
      images: [FynchPreview1, FynchPreview2, FynchPreview3],
      dice: "fynch"
    },
    {
      link: ProjectsWidgetCabyn,
      description: `With Cabyn, young people could split the value of a home 
                    up to eight ways and “co-buy” it together. As co-founder, 
                    I designed and oversaw the development of the core 
                    application.`,
      name: "Cabyn",
      path: "cabyn",
      tags: ["Product management", "Founder", "Prop tech"],
      images: [CabynPreview1, CabynPreview2, CabynPreview3],
      dice: "cabyn"
    },
    {
      link: ProjectsWidgetReputool,
      description: `Reputool generates custom reputation management strategies 
                    based on the online profiles of clients. I designed the core
                    application and oversaw its development in 2021.`,
      name: "Reputool",
      path: "reputool",
      tags: ["Product design", "Marketing", "Web application"],
      images: [ReputoolPreview1, ReputoolPreview2, ReputoolPreview3],
      dice: "reputool"
    }
  ]

  const handleMouseEnter = (selectedArrow) => {
    selectedArrow === "left" ? 
      setIsLeftArrowHovered(true) : 
      setIsRightArrowHovered(true);
  };

  const handleMouseLeave = (selectedArrow) => {
    selectedArrow === "left" ? 
      setIsLeftArrowHovered(false) : 
      setIsRightArrowHovered(false);
  };

  const handleArrowClick = (btn) => {
      if (btn > 3) {
        btn = 1;
      } else if (btn < 0) {
        btn = 3;
      }
      setSelected(btn);
  }

  const navToPortfolio = (path) => {
    navigate(`/portfolio/${path}`)
  }

  return (
    <div className='projects-widget'>
      <div className='projects-body'>
        <div className='dice-container'>
          {
            projects[selected].name === "Fynch" ? (
              <DiceFynch className="dice" />
            ) : projects[selected].name === "Cabyn" ? (
              <DiceCabyn className="dice" />
            ) : projects[selected].name === "Reputool" ? (
              <DiceReputool className="dice" />
            ) : (
              <DiceRepx className="dice" />
            )
          }
        </div>
        <img src={shadow} alt="shadow" className="dice-shadow" />
        <div className="projects-text">
          <h1>{projects[selected].name}</h1>
          <ul className='project-skills'>
            {projects[selected].tags.map((tag, i) => (
              <li key={i}>
                <button className='project-skill'>
                  {tag}
                </button>
              </li>
            ))}
          </ul>
          <p>{projects[selected].description}</p>
          <div className="project-widget-buttons">
            <SeeProjectButton
              text="See project" 
              mode="dark"
              portfolioPath={projects[selected].path}
            />
            <div className="project-nav-arrows">
              <img 
                src={isLeftArrowHovered ? ArrowHover : Arrow} 
                alt="Previous project" 
                onMouseEnter={() => handleMouseEnter('left')}
                onMouseLeave={() => handleMouseLeave('left')}
                onClick={() => handleArrowClick(selected - 1)}
                style={{rotate: '180deg'}}
              />
              <img 
                src={isRightArrowHovered ? ArrowHover : Arrow} 
                alt="Next project" 
                onMouseEnter={() => handleMouseEnter('right')}
                onMouseLeave={() => handleMouseLeave('right')}
                onClick={() => handleArrowClick(selected + 1)}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="project-preview">
            <img src={projects[selected].images[0]} alt="Fynch Preview" />
            <img src={projects[selected].images[1]} alt="Fynch Preview" />
            <img src={projects[selected].images[2]} alt="Fynch Preview" />
          </div>
    </div>
  )
}

export default ProjectsWidget