import React from 'react'
import './PortfolioMediaItem.css'

const PortfolioMediaItem = (props) => {
    const { src, alt } = props;

    const isVideo = (src) => {
        return /\.(mp4|webm|ogg)$/i.test(src);
    };

    return (
        <div className="media-item">
            <h1>{alt}</h1>
            {isVideo(src) ? (
                <video controls className="media-video">
                    <source src={src} type={`video/${src.split('.').pop()}`} />
                    Your browser does not support the video tag.
                </video>
            ) : (
                <img src={src} alt={alt} className="media-image" />
            )}
        </div>
    )
}

export default PortfolioMediaItem