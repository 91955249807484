import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css';
import Home from './pages/Home/Home';
import Navbar from './components//Navbar/Navbar';
import Portfolio from './pages/Portfolio/Portfolio';
import Footer from './components/Footer/Footer';
import Fynch from './pages/Portfolio/Fynch';
import Cabyn from './pages/Portfolio/Cabyn';
import ReputationX from './pages/Portfolio/ReputationX';
import Reputool from './pages/Portfolio/Reputool';

function App() {

  return (
    <>
      <Router>
        <Navbar />
        <div className='main-content'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/portfolio/fynch" element={<Fynch />} />
            <Route path="/portfolio/cabyn" element={<Cabyn />} />
            <Route path="/portfolio/reputationx" element={<ReputationX />} />
            <Route path="/portfolio/reputool" element={<Reputool />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </>
  );
}

export default App;
