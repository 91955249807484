// Third party libraries
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-scroll'

// Shared libraries
import { Divider } from '@mui/material'

// Relative imports
import { ViewPortfolioButton, CopyMyEmailButton } from 'components/Buttons/buttons';
import { ellipse1, ellipse2, ellipse3, profilePhoto2 } from 'assets';

// Stylesheet
import './Hero.css'

const Hero = () => {
  const navigate = useNavigate();

    return (
      <div className='hero-container'>
        <div className='main-hero'>
          <div className="left-section">
            <h1 className='hero-title'>
              Hey, I'm <span>Liam</span>.
            </h1>
            <div className='left-body'>
              <p>
                I'm a <span>product designer </span> 
                and <span>front-end programmer</span>. 
                I'm passionate about curating vibrant digital experiences.</p>
            </div>
            <ViewPortfolioButton onClick={() => navigate('/portfolio')} />
          </div>

          <div className="right-section">
            <div className="info-box">
              <img 
                className='profile' 
                src={profilePhoto2} 
                alt='profile'
              />
              <h2>Liam Campbell</h2>
              <div className="info-section">
                <span className='skill-title alma-mater'>Alma<br />mater</span>
                <p className='skill-text'>
                  University of California, Berkeley, Haas School of Business (B.Sc)
                </p>
              </div>
              <div className="info-section">
                <span className='skill-title my-skills'>Skills</span>
                <p className='skill-text'>
                  Figma, user experience design, prototyping, animation, design systems,
                  front-end programming,&nbsp;
                  <span 
                    style={{
                      textDecoration: 'underline', 
                      cursor: 'pointer'
                    }}
                  >
                    <Link 
                      to="skills-section" 
                      spy={true} 
                      smooth={true} 
                      offset={-70} 
                      duration={500}
                    >
                      see all
                    </Link>
                  </span>
                </p>
              </div>
              
              <div className="info-section">
                <span className='skill-title seeking'>Seeking</span>
                <p className='skill-text'>
                  Roles relating to <span>product design</span> and <span>UX design</span>
                </p>
              </div>
            </div>
            <CopyMyEmailButton />
          </div>
        </div>
        <Divider />
      </div>
    )
}

export default Hero