import React, { useState } from 'react'
import './Projects.css'
import ProjectsWidget from 'components/ProjectsWidget/ProjectsWidget'
import ThreeJSDice from 'components/ThreeJSDice/ThreeJSDice'
import shadow from 'assets/shadow-1.png'

const Projects = ({ onLoaded }) => {
    const [isProjectsWidgetLoaded, setIsProjectsWidgetLoaded] = useState(false);

    return (
        <div className='projects-container'>
            <div className='projects'>
                <h1>Projects</h1>
                <ProjectsWidget onLoaded={() => setIsProjectsWidgetLoaded(true)} />
            </div>
        </div>
    )
}

export default Projects