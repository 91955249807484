import React from 'react'
import { Link } from 'react-router-dom'
import './PortfolioBreadcrumbs.css'

const Breadcrumbs = (props) => {
    const { companyName } = props.data;

    return (
        <div className='breadcrumbs-container'>
            <div className="breadcrumbs">
                <p className="breadcrumbs-text"><Link className='bc-link' to='/portfolio'>Portfolio</Link> &nbsp;/ &nbsp;<span className='breadcrumbs-text--current'>{companyName}</span></p>
            </div>
        </div>
    )
}

export default Breadcrumbs