import React from 'react'
import './ViewPortfolioButton.css'
import Arrow from '../../../assets/arrow-1.svg'

const ViewPortfolioButton = (props) => {
    const { onClick } = props;

    return (
        <div className='portfolio-button' onClick={onClick}>
            <a>View portfolio</a>
            &nbsp;&nbsp;<span className='arrow bounce'><img src={Arrow} alt='arrow 1' /></span>
        </div>
    )
}

export default ViewPortfolioButton