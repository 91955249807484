import React from 'react'
import './HeaderImages.css'
import { Divider } from '@mui/material'

const HeaderImages = (props) => {
    const { headerImages } = props.data;

    const { firstImage, secondImage, thirdImage } = headerImages;

    return (
        <div className='header-images-container'>
            <div className="header-images">
                <div className="left-image">
                    <img src={firstImage} alt='Screenshot 1' />
                </div>
                <div className="right-images">
                    <img className="small-header-screenshot" src={secondImage} alt='Screenshot 2' />
                    <img className="small-header-screenshot" src={thirdImage} alt='Screenshot 3' />
                </div>
            </div>
        </div>
    )
}

export default HeaderImages