import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
// import fynchDice from './dice-fynch.glb';
// import cabynDice from './dice-cabyn.glb';
// import reputoolDice from './dice-reputool.glb';
// import repxDice from './dice-repx.glb';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import './ThreeJSDice.css';

function ThreeJSDice({ dice }) {
  const ref = useRef();
  let scene, renderer, camera, cube, directionalLight, animationFrame1;

  // const getDiceGLB = (dice) => {
  //   switch(dice) {
  //     case "fynch":
  //       return fynchDice;
  //     case "cabyn":
  //       return cabynDice;
  //     case "reputool":
  //       return reputoolDice;
  //     case "repx":
  //       return repxDice;
  //     default:
  //       return fynchDice;
  //   }
  // };

  const diceGLB = process.env.PUBLIC_URL + '/dice-fynch.glb';

  useEffect(() => {
    
    if (ref.current) {
      const height = 200;
      const aspectRatio = 30 / 9;
      const width = height;


      // Scene setup
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(20, width / height, 0.05, 1000);
      camera.position.set(0, 0, 15);

      // Renderer setup
      const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
      renderer.shadowMap.enabled = true;
      
      renderer.setSize(width, height);
      renderer.setPixelRatio(window.devicePixelRatio);
      ref.current.appendChild(renderer.domElement);
      
      const loader = new GLTFLoader();

      let cube;
      loader.load( diceGLB, function ( gltf ) {
        cube = gltf.scene; // Assuming gltf.scene is your cube
        cube.traverse(function (child) {
          if (child.isMesh) {
            const maxAnisotropy = renderer.capabilities.getMaxAnisotropy();
            child.material.map.anisotropy = maxAnisotropy;
            child.castShadow = true;
            child.receiveShadow = true;
          }
        });

        scene.add( gltf.scene );
      }, undefined, function ( error ) {
        console.error( error );
      } );

      // Plane geometry
      const planeGeometry = new THREE.PlaneGeometry(50, 50);
      planeGeometry.rotateX( - Math.PI / 2 )

      // Plane material
      const planeMaterial = new THREE.ShadowMaterial();
      planeMaterial.opacity = 0.2;

      const plane = new THREE.Mesh(planeGeometry, planeMaterial);
      plane.position.z = -2;
      plane.rotation.x = Math.PI / 2;
      plane.receiveShadow = true;
      scene.add(plane);

      // OrbitControls
      // const controls = new OrbitControls(camera, renderer.domElement);

      // Lights
      const ambientLight = new THREE.AmbientLight(new THREE.Color(1, 1, 1, 1));
      scene.add(ambientLight);

      // Directional light
      const directionalLight = new THREE.DirectionalLight(0xffffff, 2);
      directionalLight.position.set(0, 0, 10);
      
      directionalLight.shadow.bias = 0.2;

      scene.add(directionalLight);

      // PointLight
      const pointLight = new THREE.PointLight(0xffffff, 400, 100);
      pointLight.position.set(10, 10, 10);

      // Add the PointLight to the scene
      scene.add(pointLight);

      // Animation loop
      const animate = () => {
        animationFrame1 = requestAnimationFrame(animate);
        
        if (cube) {
          cube.rotation.x += 0.01;
          cube.rotation.y += 0.01;
        }

        renderer.render(scene, camera);
      };
      animate();

      // Resize handling
      const handleResize = () => {
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
      };

      window.addEventListener('resize', handleResize);

      const cleanUpScene = () => {
        window.removeEventListener('resize', handleResize);

        renderer.dispose();
        // controls.dispose();

        if (ref.current && ref.current.contains(renderer.domElement)) {
          ref.current.removeChild(renderer.domElement);

          if (cube) {
            cube.traverse(function(child) {
              if (child.isMesh) {
                if (child.geometry) {
                  child.geometry.dispose();
                }
                if (child.material) {
                  if (child.material instanceof THREE.Material) {
                    if (child.material.map) child.material.map.dispose();
                    child.material.dispose();
                  } else if (Array.isArray(child.material)) {
                    child.material.forEach(material => {
                      if (material.map) material.map.dispose();
                      material.dispose();
                    });
                  }
                }
              }
            });
          }

          while(scene.children.length > 0){ 
            scene.remove(scene.children[0]); 
          }

          if (directionalLight.shadow.map) {
            directionalLight.shadow.map.dispose();
          }
          if (pointLight.shadow.map) {
            pointLight.shadow.map.dispose();
          }

          cube = null;

          cancelAnimationFrame(animationFrame1);
        }
      };

      const loadNewDice = () => {
        loader.load(diceGLB, function (gltf) {
            // Add the new model to the scene
            // ...
        }, undefined, function (error) {
            console.error(error);
        });
      };

      return () => {
        cleanUpScene();
      };
    } else return;
  }, []);

  return (
    <div className="dice-renderer-container">
      <div className="dice-renderer">
        <div ref={ref} />
      </div>
    </div>
  );
}

export default ThreeJSDice;