import React, { useState } from 'react'
import './CopyMyEmailButton.css'
import LinkIcon1 from '../../../assets/link-icon/link-icon-1.svg'
import LinkIcon2 from '../../../assets/link-icon/link-icon-2.svg'
import LinkIcon3 from '../../../assets/link-icon/link-icon-3.svg'

const CopyMyEmailButton = (props) => {
    const { size } = props;
    const [hover, setHover] = useState(false);
    const [message, setMessage] = useState("Copy to clipboard");
    const [subtextVisible, setSubtextVisible] = useState(false);

    const handleButtonClick = () => {
        navigator.clipboard.writeText("liam@liamcampbell.net");
        setMessage('Copied!');
        setTimeout(() => {
            setSubtextVisible(false);
        }, 1000);  // Introduce delay for resetting message
    };

    const handleMouseEnter = () => {
        setHover(true);
        setSubtextVisible(true);
        if (message === 'Copied!') {
            setMessage('Copy to clipboard');
        }
    };

    const handleMouseLeave = () => {
        setHover(false);
        setSubtextVisible(false);
    }

    return (
        <div className={`copy-my-email-btn-container ${size === 'small' ? 'small' : ''}`}>
            <button 
                className={`copy-my-email-btn ${size === 'small' ? 'small' : 'large'}`} 
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleButtonClick}
            >
                <div>
                Copy my email
                </div>
                <div className='link-icon'>
                    <img className='link-icon-1 expand' src={LinkIcon1} alt='link icon piece 1' style={{ translate: '0' }} />
                    <img className='link-icon-2 expand' src={LinkIcon2} alt='link icon piece 2' style={{ translate: '-5px -3.5px' }} />
                    <img className='link-icon-3 expand' src={LinkIcon3} alt='link icon piece 3' style={{ translate: '-10px -4px' }} />
                </div>
            </button>
            <p className={`copy-email-subtext ${hover && subtextVisible ? 'visible' : ''}`}>{message}</p>
        </div>
    )
}

export default CopyMyEmailButton