import React from 'react'
import Three from 'components/Three/Three'
import LetsConnect from '../Home/Sections/LetsConnect/LetsConnect'
import PortfolioBreadcrumbs from '../../components/PortfolioBreadcrumbs/PortfolioBreadcrumbs'
import HeaderImages from '../../components/HeaderImages/HeaderImages'
import PortfolioTitle from '../../components/PortfolioTitle/PortfolioTitle'
import OpportunitySection from '../../components/OpportunitySection/OpportunitySection'
import TimelineSection from '../../components/TimelineSection/TimelineSection'
import WireframeSection from 'components/WireframeSection/WireframeSection'
import PortfolioMedia from '../../components/PortfolioMedia/PortfolioMedia'

import FynchIcon from '../../assets/fynch-icon.svg'

import HeaderImage1 from 'assets/fynch-media/fynch-projects-preview1.png'
import HeaderImage2 from 'assets/fynch-media/fynch-projects-preview2.png'
import HeaderImage3 from 'assets/fynch-media/fynch-projects-preview3.png'

import FynchWireframe from 'assets/fynch-media/fynch-wireframe-1.jpg'
import FynchStyleGuide from 'assets/fynch-media/fynch-styleguide.jpg'
import FynchStyleGuideMobile from 'assets/fynch-media/fynch-styleguide-mobile.jpg'

import SignInVideo from '../../assets/fynch-media/fynch-sign-in.mp4'
import EditSettingsVideo from '../../assets/fynch-media/fynch-edit-settings-functionality.mp4'
import OrderSectionVideo from '../../assets/fynch-media/fynch-interactive-order-section.mp4'

import ArticleDetails1 from '../../assets/fynch-media/fynch-article-details.png'
import ArticleDetails2 from '../../assets/fynch-media/fynch-article-details-2.png'
import Invoices from '../../assets/fynch-media/fynch-invoices.png'
import MultipleTeamsFunctionality from '../../assets/fynch-media/fynch-multiple-teams-functionality.png'
import NavbarNotifications from '../../assets/fynch-media/fynch-navbar-notifications.png'
import OrderDetailsAtAGlance from '../../assets/fynch-media/fynch-order-details-at-a-glance.png'
import ProfileMenu from '../../assets/fynch-media/fynch-profile-menu.png'
import SmartNotes1 from '../../assets/fynch-media/fynch-smart-notes-1.png'
import SmartNotes2 from '../../assets/fynch-media/fynch-smart-notes-2.png'

import './PortfolioPage.css'

const Fynch = () => {
    const data = {
        companyName: "Fynch",
        icon: FynchIcon,
        headerImages: {
            firstImage: HeaderImage1,
            secondImage: HeaderImage2,
            thirdImage: HeaderImage3
        },
        location: "San Francisco",
        duration: "12 months",
        teamSize: "8",
        roles: [
            "Product design",
            "UX",
            "Brand design",
            "Frontend development"
        ],
        areas: [
            "Marketing",
            "Web application"
        ],
        summary: "During most of 2023, I was the lead designer at Fynch, a startup building AI-enabled marketing tools for small businesses. I researched, designed, and led development on the core product alongside two full-stack engineers.",
        opportunity: (
            <p>
                Small marketing firms typically spend 25-30% of their marketing budgets 
                on content marketing, and sourcing content tends to be <strong>complex 
                and expensive.</strong> The founders at Fynch saw an opportunity for a 
                centralized app for <strong>ordering bundles of diverse, rich, and 
                seo-optimized content quickly and affordably.</strong>
            </p>
        ),
        timeline: [
            {title: "Research", content: "Competitive analysis, user interviews", width: "32%"},
            {title: "Ideation", content: "Sketches, moodboards, affinity mapping", width: "35.5%"},
            {title: "Design", content: "Wireframes, design systems, high-fidelity prototyping, usability testing", width: "44.5%"},
            {title: "Branding", content: "Logo design, crafting a compelling & unique product tone", width: "62.75%"}
        ],
        media: [
            {src: SignInVideo, alt: "Signing in"},
            {src: EditSettingsVideo, alt: "Editing settings"},
            {src: OrderSectionVideo, alt: "Interactive order section"},
            {src: ArticleDetails1, alt: "Article details 1"},
            {src: ArticleDetails2, alt: "Article details 2"},
            {src: Invoices, alt: "Accessing invoices"},
            {src: MultipleTeamsFunctionality, alt: "Multiple teams functionality"},
            {src: NavbarNotifications, alt: "Navbar notifications"},
            {src: OrderDetailsAtAGlance, alt: "Order details at a glance"},
            {src: ProfileMenu, alt: "Profile menu"},
            {src: SmartNotes1, alt: "Smart notes 1"},
            {src: SmartNotes2, alt: "Smart notes 2"},
        ],
        wireframes: [
            {src: FynchWireframe, alt: "Wireframe"},
            {src: FynchWireframe, alt: "Wireframe"},
        ],
        styleguides: [
            {src: FynchStyleGuide, alt: "Style Guide"},
            {src: FynchStyleGuideMobile, alt: "Style Guide"},
        ]
    }

    return (
        <>
            <div className="portfolio-renderer-container">
                <Three 
                    viewHeight={270}
                    ind={47}
                />
            </div>
            <PortfolioBreadcrumbs class data={data} />
            <HeaderImages data={data} />
            <PortfolioTitle data={data} />
            <OpportunitySection data={data} />
            <TimelineSection data={data} />
            <WireframeSection data={data.wireframes} />
            <WireframeSection data={data.styleguides} />
            <PortfolioMedia data={data} />
            <LetsConnect />
        </>
    )
}

export default Fynch