import React from 'react'
import './PortfolioTitle.css'

const PortfolioTitle = (props) => {
    const { companyName, icon, location, duration, teamSize, roles, areas, summary } = props.data;

    return (
        <>
            <div className="portfolio-title-container">
                <div className="portfolio-title">
                    <div className="portfolio-title--main">
                        <div className="portfolio-title--main-title">
                            <img src={icon} alt="Fynch Icon" style={{ height: '40px' }}/>
                            <h1>{companyName}</h1>
                        </div>
                        <div className="portfolio-title--main-data">
                            <ul>
                                <li><p><strong>Location</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {location}</p></li>
                                <li><p><strong>Duration</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {duration}</p></li>
                                <li><p><strong>Team size</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {teamSize}</p></li>
                            </ul>
                        </div>
                    </div>
                    <div className="portfolio-title--tags">
                        <div className="skill-section--portfolio">
                            <div className='skill-section-title-box--portfolio'>
                                <p className='skill-section-title--portfolio'>Roles</p>
                            </div>
                            <ul className='skilltags'>
                                {roles.map((role, i) => {
                                    return (
                                        <li key={i}><button className='skilltag-gray'>{role}</button></li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="skill-section--portfolio">
                            <div className='skill-section-title-box--portfolio'>
                                <p className='skill-section-title--portfolio'>Areas</p>
                            </div>
                            <ul className='skilltags'>
                                {areas.map((area, i) => {
                                    return (
                                        <li key={i}><button className='skilltag-gray'>{area}</button></li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="portfolio-title--description">{summary}</div>
                </div>
            </div>
        </>
    )
}

export default PortfolioTitle