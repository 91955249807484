import React from 'react'
import './PortfolioMedia.css'
import PortfolioMediaItem from '../PortfolioMediaItem/PortfolioMediaItem'

const PortfolioMedia = (props) => {
    const { media } = props.data;

    return (
        <div className="portfolio-media-container">
            <div className="portfolio-media">
                <h1>Final design</h1>
                <div className="media-items">
                    {media.map((mediaItem, i) => {
                        return (
                            <PortfolioMediaItem key={i} src={mediaItem.src} alt={mediaItem.alt} />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default PortfolioMedia