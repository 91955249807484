import React from 'react'
import './OpportunitySection.css'

const OpportunitySection = (props) => {
    const { opportunity } = props.data;

    return (
        <div className="opportunity-section-container">
            <div className="opportunity-section">
                <h1>Opportunity</h1>
                <div className="opportunity-section-box">
                    {opportunity}
                </div>
            </div>
        </div>
    )
}

export default OpportunitySection