// Third party libraries
import React from 'react'

// Shared libraries
import { skills } from 'skills'

// Stylesheet
import './SkillsWidget.css'

const SkillsWidget = () => {

    return (
        <div className="skills-widget">
            <p className='skills-description'>Stuff I'm good at.</p>
            <div className="skill-sections">
                {skills.map((skillBlock, i) => (
                  <div className="skill-section" key={i}>
                    <div className='skill-section-title-box'>
                      <p className='skill-section-title'>{skillBlock.type}</p>
                    </div>
                    <ul className='skilltags'>
                      {skillBlock.skills.map((skill, j) => (
                        <li key={j}>
                          <button className="skilltag-gray">
                            {skill.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
        </div>
    )
}

export default SkillsWidget