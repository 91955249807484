import {
    CabynIcon,
    CabynThumbnail,
    FynchIcon,
    FynchThumbnail,
    ReputoolIcon,
    ReputoolThumbnail,
    RepxIcon,
    RepxThumbnail,
  } from 'assets'

const projects = [
    {
      name: 'Fynch',
      icon: FynchIcon,
      thumbnail: FynchThumbnail,
      path: '/portfolio/fynch'
    },
    {
      name: 'Cabyn',
      icon: CabynIcon,
      thumbnail: CabynThumbnail,
      path: '/portfolio/cabyn'
    },
    {
      name: 'Reputool',
      icon: ReputoolIcon,
      thumbnail: ReputoolThumbnail,
      path: '/portfolio/reputool'
    },
    {
      name: 'Reputation X',
      icon: RepxIcon,
      thumbnail: RepxThumbnail,
      path: '/portfolio/reputationx'
    },
]

export { projects };