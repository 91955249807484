import React from 'react'
import './LetsConnect.css'
import CopyMyEmailButton from '../../../../components/Buttons/CopyMyEmailButton/CopyMyEmailButton'
import AddMeOnLinkedinButton from '../../../../components/Buttons/AddMeOnLinkedinButton/AddMeOnLinkedinButton'

const LetsConnect = () => {
    return (
        <div className='lets-connect-container'>
            <div className="lets-connect">
                <h2 className='lets-connect-title'>Let's connect</h2>
                <div className="lets-connect-buttons">
                    <CopyMyEmailButton size='small' />
                    <AddMeOnLinkedinButton />
                </div>
            </div>
        </div>
    )
}

export default LetsConnect