import React, { useState, useEffect } from "react";
import Hero from "./Sections/Hero/Hero";
import Projects from "./Sections/Projects/Projects";
import Skills from "./Sections/Skills/Skills";
import AboutMe from "./Sections/AboutMe/AboutMe";
import LetsConnect from "./Sections/LetsConnect/LetsConnect";

import Three from 'components/Three/Three'

import './Home.css'

const Home = () => {

  return (
    <>
      <div className="renderer-container" >
        <Three 
          viewHeight={200}
          ind={47} 
        />
      </div>
      <Hero />
      <Projects />
      <Skills />
      <AboutMe />
      <LetsConnect />
    </>
  );
};

export default Home;
