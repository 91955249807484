import React from 'react'
import { Divider } from '@mui/material'
import './TimelineSection.css'

const TimelineSection = (props) => {
    const { timeline } = props.data;

    const gridlines = [];
    for (let i = 0; i < 12; i++) {
      gridlines.push(
        <Divider 
          key={i}
          className='divider--timeline' 
          orientation='vertical' 
          sx={{ borderColor: 'rgb(18 18 18 / 12%)' }} 
        />
      );
    }

    return (
      <div className="timeline-section-container">
        <div className="timeline-section">
          <h1>Process</h1>
          <div className="timeline-section-box">
            <div className="timeline-section-box--items">
              <div className="timeline-section-box--item" style={{
                width: timeline[0].width,
                backgroundColor: '#f5f5f5',
                margin: '3% 0 -3% 0.5%'
              }}>
                <h1 style={{color: '#212121'}}>{timeline[0].title}</h1>
                <p style={{color: '#212121'}}>{timeline[0].content}</p>
              </div>
              <div className="horizontal-stack">
                <div className="timeline-section-box--item" style={{
                    width: timeline[1].width,
                    backgroundColor: '#212121',
                    margin: '3.5% 0 -3.5% 9.6%'
                }}>
                  <h1>{timeline[1].title}</h1>
                  <p>{timeline[1].content}</p>
                </div>
                <div className="timeline-section-box--item" style={{
                  width: timeline[2].width,
                  backgroundColor: '#FA7282',
                  margin: '3.5% 0 -3.5% .5%'
                }}>
                  <h1>{timeline[2].title}</h1>
                  <p>{timeline[2].content}</p>
                </div>
              </div>
              <div className="timeline-section-box--item" style={{
                width: timeline[3].width,
                backgroundColor: '#434343',
                margin: '4% 0 -4% 36.75%'
              }}>
                <h1>{timeline[3].title}</h1>
                <p>{timeline[3].content}</p>
              </div>
            </div>
            <div className="timeline-section-box--gridlines">
              {gridlines}
            </div>
          </div>
        </div>
      </div>
    )
}

export default TimelineSection