import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import colors from 'nice-color-palettes';
import { fragment } from './fragment.js';
import { vertex } from './vertex.js';
import './Three.css';

function ThreeComponent( { viewHeight, ind } ) {
  const ref = useRef();

  useEffect(() => {
    
    if (ref.current) {
      const height = viewHeight;
      const aspectRatio = 30 / 9;
      const width = height * aspectRatio;

      // let ind = Math.floor(Math.random() * colors.length);
      // console.log(ind)
      let palette = colors[47].map(color => new THREE.Color(color));

      // Scene setup
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(30, width / height, 0.05, 1000);
      camera.position.set(0, 0, 0.8);

      // Renderer setup
      const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
      
      renderer.setSize(width, height);
      renderer.setPixelRatio(window.devicePixelRatio);
      ref.current.appendChild(renderer.domElement);
      

      // OrbitControls
      // const controls = new OrbitControls(camera, renderer.domElement);

      // Lights
      const ambientLight = new THREE.AmbientLight(new THREE.Color(1, 1, 1, 1));
      scene.add(ambientLight);

      // Shader material
      const material = new THREE.ShaderMaterial({
        extensions: {},
        side: THREE.DoubleSide,
        uniforms: {
          time: { value: 0 },
          uColor: { value: palette },
          resolution: { value: new THREE.Vector4() },
        },
        vertexShader: vertex,
        fragmentShader: fragment,
      });

      // Geometry
      const geometry = new THREE.PlaneGeometry(2.4, 2.2, 180, 80);
      const plane = new THREE.Mesh(geometry, material);
      scene.add(plane);

      // Animation loop
      let time = 0;
      let animationFrame1 = null;
      const animate = () => {
        animationFrame1 = requestAnimationFrame(animate);
        time += 0.0005;
        material.uniforms.time.value = time;
        renderer.render(scene, camera);
      };
      animate();

      // Resize handling
      const handleResize = () => {
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
      };

      window.addEventListener('resize', handleResize);

      // Cleanup function
      return () => {
        window.removeEventListener('resize', handleResize);

        geometry.dispose();
        if (material.map) material.map.dispose();
        material.dispose();
        plane.geometry.dispose();
        plane.material.dispose();

        renderer.dispose();
        // controls.dispose();

        cancelAnimationFrame(animationFrame1);

        if (ref.current && ref.current.contains(renderer.domElement)) {
          ref.current.removeChild(renderer.domElement);
        }
      };
    }
  }, []);

  return (
    <div ref={ref} />
  );
}

export default ThreeComponent;